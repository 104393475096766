<template>
  <section class="container-fluid live-detail-inner-section" v-if="detail">
    <div class="container lib-inner-container">
      <ol class="bg-transparent breadcrumb p-0">
        <li class="breadcrumb-item"><nuxt-link to="/khoa-hoc">Khóa học</nuxt-link></li>
        <li class="breadcrumb-item"><nuxt-link to="/khoa-hoc">{{detail.class_name}}</nuxt-link></li>
        <li class="breadcrumb-item"><nuxt-link to="/khoa-hoc">{{detail.category_name}}</nuxt-link></li>
        <li class="breadcrumb-item"><nuxt-link :to="'/khoa-hoc/'+detail.topic.slug">{{detail.topic.name}}</nuxt-link></li>
        <li class="breadcrumb-item" aria-current="page">{{detail.name}}</li>
      </ol>
      <p class="color-darkgrey video-detail-content">{{detail.topic.name}}</p>
      <div class="statistical-content d-flex align-items-center">
        <template v-if="detail.teachers">
          <span class="d-flex align-items-center statistical-item" v-for="(teacher, ind) in detail.teachers" :key="ind">
            <img src="~/assets/icons/library/user.svg" alt=""> Giáo viên: {{ teacher.name }}
          </span>
        </template>
        <span class="d-flex align-items-center statistical-item">
          <img src="~/assets/icons/library/play-btn-icon.svg" alt="">{{ detail.topic.number_video }} Video
        </span>
        <span class="d-flex align-items-center statistical-item pointer" :class="{'is-active': detail.topic.is_followed}" @click="detail.topic.is_followed ? handleUnFollow() : handleFollow()">
          <img v-if="!detail.topic.is_followed" src="~/assets/icons/library/circle-plus.svg" alt="follow-icon-unactive" class="">
          <img v-else src="~/assets/icons/library/circle-plus-blue.svg" alt="follow-icon-active" class="">
          Theo dõi
        </span>
      </div>
      <div class="row video-detail-row">
        <div class="col-12 col-lg-8" ref="video-content-left">
          <div class="video-tab bg-white">
            <PlayerVideo :item="detail" :autoplay="true" :muted="true" :live="!detail.is_playback" />
            <div class="video-name d-flex align-items-center">
              <p class="mb-0 v-name w-100">
                <img src="~/assets/icons/library/live-stream-icon-red.svg" class="mr-2" />{{ detail.name }} <span class="note">{{ statePlay }}</span>
              </p>
              <span class="ml-2 ml-lg-4 action flex-shrink-0 d-flex align-items-center pointer" @click="showReport = true">
                <img src="~/assets/icons/library/warning-icon.svg" alt="">
                Báo lỗi
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-3 mt-lg-0">
          <div class="sticky-ls">
            <div class="chat-block h-100">
              <div class="chat-header ">
                <div class="live-stream-detail d-flex align-items-start">
                  <img src="~/assets/icons/library/live-stream-icon-red.svg" class="live-stream-icon" alt="live-stream-icon-red">
                  <span class="live-stream-name color-darkgrey">{{detail.name}}</span>
                </div>
              </div>
              <div class="chat-wr">
                <div class="comment" ref="messagesContainer" :style="{height: height + 'px'}">
                  <div class="each-comment d-flex flex-wrap align-items-start pb-2 pb-lg-3" v-for="(it, ind) in listMessage" :key="ind">
                    <ImageCustom :src="it.avatar" type="avatar" width="26" height="26" alt="user-avatar" class="cmt-user-avatar rounded-circle" />
                    <div>
                      <div class="user-inf d-flex align-items-center">
                        <span class="user-name color-darkgrey">{{it.name}}</span>
                        <img v-if="it.is_teacher" src="/icon/live/check-guru.svg" />
                        <span class="user-comment-time">{{it.created_at | parseTime('{h}:{i} {d}/{m}/{y}')}}</span>
                      </div>
                      <p class="comment-text">{{it.message}}</p>
                    </div>
                  </div>
                </div>
                <div class="typing-block d-flex align-items-center">
                  <ImageCustom :src="user.avatar" type="avatar" width="26" height="26" alt="user-avatar" class="mr-2 rounded-circle" />
                  <input :disabled="detail.is_playback === 1" type="text" class="w-100 border-0 bg-transparent outline-none" v-model="message" placeholder="Ý kiến thảo luận..." @keyup.enter="sendMessage">
                  <img src="~/assets/icons/library/paper-plane.svg" alt="paper-plane" class="pointer" @click="sendMessage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-report-video" size="sm" v-model="showReport" no-close-on-backdrop no-close-on-esc hide-footer hide-header centered>
      <img class="close" src="/icon/library/close.svg" alt="close" @click="showReport = false"/>
      <div class="form-report">
        <div class="header-report">
          <img src="~/assets/images/icons/library/report.png" alt="report" class="report" />
          <h2>Báo lỗi Video</h2>
        </div>
        <div class="line"></div>
        <div class="list-reason">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="reason-1" name="reason" :value="1" v-model="error">
            <label class="custom-control-label" for="reason-1">Lỗi không xem được video</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="reason-2" name="reason" :value="2" v-model="error">
            <label class="custom-control-label" for="reason-2">Lỗi khác</label>
          </div>
          <textarea class="reason-text resize-none" v-model="reasonText" placeholder="Vui lòng mô tả lại lỗi bạn gặp" v-if="error === 2"></textarea>
        </div>
        <div class="action">
          <button @click="reportVideo">Gửi báo lỗi</button>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import PlayerVideo from '~/components/player/video.vue'
import Resource from '~/common/api/resource'
const livestreamResource = new Resource('library/collection/getBySlug')
const followResource = new Resource('library/topic/follow')
const unFollowResource = new Resource('library/topic/unFollow')
const reportVideoResource = new Resource('library/video/report')
const chatResource = new Resource('chat/message')
export default {
  components: {
    PlayerVideo
  },
  layout: 'thu-vien',
  middleware: 'authenticated',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const token = store.state.token
    const { data: detail } = await livestreamResource.list({ slug: params.slug }, token)
      .catch((err) => {
        if (err.response.data.status === 404) {
          return redirect('/')
        }
        return error({ statusCode: err.response.data.status })
      })
    if (!detail) {
      return redirect('/')
    }
    if (!detail.is_playback) {
      detail.url = detail.link_livestream
      detail.file_type = 'application/x-mpegURL'
    }
    // eslint-disable-next-line no-empty
    if ((detail.started && !detail.ended) || (detail.is_playback)) {
    } else {
      return redirect('/khoa-hoc/' + detail.topic.slug)
    }
    return {
      detail,
      message: ''
    }
  },
  data () {
    return {
      showReport: false,
      error: 1,
      reasonText: '',
      loading: false,
      query: {
        offset: 0,
        limit: 20,
        channel: '',
        type: 'NO_AUDIO',
        sort: 'desc'
      },
      listMessage: [],
      height: 430
    }
  },
  computed: {
    token () {
      return this.$store.state.token
    },
    user () {
      return this.$store.state.user
    },
    statePlay () {
      if (this.detail.ended) {
        return 'Đã kết thúc'
      }
      const state = this.detail.is_playback ? 'Phát lại' : `Đang trực tiếp ${this.formatSeconds(this.detail.duration_livestream)} phút`
      return state
    }
  },
  created () {
    this.query.channel = this.detail.id
  },
  mounted () {
    this.initChat()
    this.height = this.$refs['video-content-left'].clientHeight - 115
    setInterval(() => {
      this.detail.duration_livestream++
    }, 1000)
  },
  sockets: {
    connect () {
      if (this.isOffline) {
        this.initChat()
      }
    },
    NEW_MESSAGE (msg) {
      this.listMessage.push(msg)
      this.scrollToBottom()
    },
    END_LIVESTREAM () {
      this.detail.ended = 1
    }
  },
  methods: {
    formatSeconds (secs, showSecond = false) {
      const secNum = parseInt(secs, 10)
      const hours = Math.floor(secNum / 3600)
      const minutes = Math.floor(secNum / 60) % 60
      const seconds = secNum % 60
      if (showSecond) {
        return [hours, minutes, seconds].map(v => v < 10 ? '0' + v : v).filter((v, i) => v !== '00' || i > 0).join(':')
      }
      if (hours >= 1) {
        return [hours, minutes].map(v => v < 10 ? '0' + v : v).filter((v, i) => v !== '00' || i > 0).join(':')
      }
      if (minutes) {
        return [minutes].map(v => v < 10 ? '0' + v : v).filter((v, i) => v !== '00' || i > 0).join(':')
      }
      return '00'
    },
    handleFollow () {
      followResource.store({ topic_id: this.topic.id })
        .then((res) => {
          this.topic.is_followed = 1
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleUnFollow () {
      unFollowResource.store({ topic_id: this.topic.id })
        .then((res) => {
          this.topic.is_followed = 0
        })
        .catch((err) => {
          console.log(err)
        })
    },
    reportVideo () {
      this.$notify.closeAll()
      if (this.processing) { return }
      if (this.error !== 1) {
        if (!this.reasonText) {
          this.$notify({
            type: 'error',
            message: 'Chưa nhập lý do'
          })
          return
        }
        if (this.reasonText.length > 255) {
          this.$notify({
            type: 'error',
            message: 'Nội dung không dài quá 255 ký tự'
          })
          return
        }
      }
      const text = this.error === 1 ? 'Lỗi không xem được video' : (this.reasonText || 'Lỗi khác')
      const formData = {
        video_id: this.detail.id,
        reason: text
      }
      this.processing = true
      reportVideoResource.store(formData)
        .then((res) => {
          this.showReport = false
          this.$notify({
            type: 'success',
            message: 'Gửi báo lỗi thành công'
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    async getListMessage () {
      if (this.loading) { return }
      this.loading = true
      const { data } = await chatResource.list(this.query)
      if (data.length) {
        this.query.offset += this.query.limit
        this.loading = false
      }
      // data.reverse()
      this.listMessage.push(...data)
    },
    initChat () {
      this.$socket.emit('JOIN_CHANNEL', {
        channel: this.detail.id
      })
      this.loading = false
      this.query.offset = 0
      this.listMessage = []
      this.getListMessage()
    },
    sendMessage () {
      if (this.detail.is_playback) { return }
      this.message = this.message.trim()
      if (this.message.length > 255) {
        this.$message({
          type: 'warning',
          message: 'Tin nhắn không thể dài hơn 255 ký tự'
        })
        return
      }
      if (!this.message) { return }
      if (this.loading) { return }
      this.loading = true
      const msg = {
        avatar: this.user.avatar,
        name: this.user.fullName,
        type: 'TEXT',
        message: this.message,
        created_at: Math.floor(Date.now() / 1000),
        file: '',
        file_name: '',
        is_teacher: 0
      }
      this.$socket.emit('NEW_MESSAGE', msg)
      this.listMessage.push(msg)
      this.message = ''
      this.scrollToBottom()
      this.loading = false
    },
    scrollToBottom () {
      setTimeout(() => {
        this.$nextTick(() => {
          const content = this.$refs.messagesContainer
          if (content) {
            content.scrollTop = 10000000
          }
        })
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
  .live-detail-inner-section {
    padding: 2.25rem 0 6rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      padding: 1.5rem 0 3rem;
    }
    .breadcrumb {
      .breadcrumb-item {
        font-size: .875rem;
        line-height: 1.2;
        color: #777777;
      }
    }
    .sticky-ls {
      height: fit-content;
      .chat-block {
        background: #ffffff;
        box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.07);
        border-radius: .5rem;
        padding: .75rem 0px;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          padding: .5rem 0px;
        }
        .chat-header {
          border-bottom: 1px solid #eeeeee;
          padding: 0px 1rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            padding: 0px .75rem;
          }
          .live-stream-detail {
            margin-bottom: .8rem;
            gap: .75rem;
            @media (max-width: 1366px) {}
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {
              gap: .5rem;
            }
            .live-stream-name {
              font-weight: 600;
              font-size: 1rem;
            }
          }
        }
        .typing-block {
          gap: .25rem;
          background: #f4f4f4;
          border-radius: 3.75rem;
          padding: .35rem .625rem;
          margin: 0 .75rem .25rem 1.25rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            margin: 0 .5rem .25rem 1rem;
          }
          input {
            font-size: .875rem;
            &::placeholder {
              color: #959595;
            }
          }
        }
        .comment {
          // height: 430px;
          // max-height: 430px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding: 1.75rem .75rem 1rem 1.25rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            padding: 1rem .5rem .75rem 1rem;
            max-height: 20rem;
          }
          &::-webkit-scrollbar {
            width: .25rem;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: #c4c4c4;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          .each-comment {
            gap: .5rem;
            .user-inf {
              gap: .625rem;
              .user-name {
                font-weight: 600;
                font-size: .875rem;
              }
              .user-comment-time {
                font-size: .875rem;
                color: #888888;
              }
            }
            .comment-text {
              padding: .3rem 0;
              font-size: .875rem;
              color: #272727;
              margin: 0;
            }
            .btn-action {
              font-weight: 500;
              font-size: .8rem;
              color: #ababab;
            }
            .gr-actions {
              gap: .625rem;
            }
          }
          .child-cmt-list {
            padding-left: 1.25rem;
            @media (max-width: 1366px) {}
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {
              padding-left: 1rem;
            }
            .more-child-counter {
              font-size: .875rem;
              color: #6491dd;
              padding: .625rem 0;
            }
          }
        }
      }
    }
    .video-detail-content {
      font-weight: 700;
      line-height: 1.3;
      font-size: 1.5rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        font-size: 1.25rem;
      }
    }
    .video-detail-row {
      margin-top: 1rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        margin-top: .75rem;
      }
    }
    .statistical-content {
      gap: 2.375rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        gap: 1.5rem;
      }
      .statistical-item {
        gap: .35rem;
        color: #888888;
        font-size: 1rem;
        &.is-active {
          color: #4086ff;
        }
      }
    }
    .video-tab {
      box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.07);
      border-radius: .5rem;
      .video-name {
        padding: 1.5rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          padding: 1rem;
        }
        .v-name {
          font-weight: 700;
          font-size: 1.25rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            font-size: 1rem;
          }
          span{
            font-weight: normal;
            font-size: .875rem;
            color: #777777;
            gap: .3rem;
          }
        }
        .action {
          gap: .3rem;
          font-weight: 400;
          font-size: 1.125rem;
          text-align: right;
          color: #6b6d70;
        }
      }
    }
  }
</style>
<style lang="scss">
  #modal-report-video{
    .modal-dialog{
      max-width: 21rem;
      .modal-content{
        border-radius: .875rem;
      }
      .modal-body{
        position: relative;
        padding:1.4rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          padding:1rem;
        }
        .close{
          position: absolute;
          right: .75rem;
          top: .75rem;
          cursor: pointer;
        }
        .form-report{
          .header-report{
            text-align: center;
            h2 {
              font-weight: bold;
              line-height: 1.2;
              color: #333333;
              font-size: 1.5rem;
              margin:1.25rem 0 1.125rem;
              @media (max-width: 1366px) {}
              @media (max-width: 1200px) {}
              @media (max-width: 992px) {}
              @media (max-width: 768px) {}
              @media (max-width: 576px) {
                font-size: 1.25rem;
                margin:1rem 0;
              }
            }
          }
          .line{
            border-top: 1px solid #EEEEEE;
          }
          .list-reason{
            > div{
              padding-top: 1rem;
              @media (max-width: 1366px) {}
              @media (max-width: 1200px) {}
              @media (max-width: 992px) {}
              @media (max-width: 768px) {}
              @media (max-width: 576px) {
                padding-top: .75rem;
              }
              input {
                cursor: pointer;
                margin-right: 1.25rem;
                @media (max-width: 1366px) {}
                @media (max-width: 1200px) {}
                @media (max-width: 992px) {}
                @media (max-width: 768px) {}
                @media (max-width: 576px) {
                  margin-right: 1rem;
                }
              }
              label{
                cursor: pointer;
                font-style: normal;
                font-weight: normal;
                line-height: 1.2;
                color: #333333;
                padding-top: 3px;
                font-size: 1.125rem;
                padding-left: 1.25rem;
                &::before, &::after{
                  width: 1.25rem;
                  height: 1.25rem;
                  outline: none !important;
                  box-shadow: none !important;
                  border-color:var(--warning);
                }
              }
              .custom-control-input:checked {
                ~ .custom-control-label::before {
                  background: #fff;
                }
                ~ .custom-control-label::after {
                  background-image: url('~/assets/images/icons/library/circle-radio.png');
                }
              }
            }
            .reason-text{
              margin-top: .625rem;
              border: 1px solid #E9E9E9;
              border-radius: .75rem;
              padding:.625rem;
              font-size: .75rem;
              line-height: 1.2;
              width:100%;
              height:6.25rem;
              &::placeholder{
                color: #888888;
              }
            }
          }
          .action{
            text-align: center;
            padding-top: .875rem;
            button{
              background:#004390;
              border-radius: 1.25rem;
              padding:.625rem 1.25rem;
              font-weight: bold;
              font-size: 1rem;
              line-height: 1.2;
              color: #FFFFFF;
              border: none;
            }
          }
        }
      }
    }
  }
</style>
